import { Glyph } from '@visx/glyph';

type GlyphProps = {
  left: number;
  top: number;
};
const CloudGlyph = ({ left, top }: GlyphProps) => {
  return (
    <Glyph left={left} top={top}>
      <svg width="28" height="26" viewBox="0 0 28 26" fill="none">
        <path
          d="M6.74431 16.7834H1.66137C1.40038 16.1955 1.25342 15.5443 1.25342 14.8602C1.25342 12.4606 3.03979 10.4791 5.35575 10.1725C5.31774 9.91661 5.29747 9.65815 5.29747 9.3921C5.29747 6.55417 7.59822 4.25342 10.4361 4.25342C13.0638 4.25342 15.2277 6.22477 15.5368 8.76877C15.9802 8.64461 16.449 8.57619 16.933 8.57619C19.0893 8.57619 20.934 9.90394 21.6966 11.7866C21.9323 11.7486 22.1705 11.7258 22.4163 11.7258C24.8082 11.7258 26.7466 13.6642 26.7466 16.0562C26.7466 16.3045 26.7238 16.5477 26.6858 16.7834H19.2464"
          fill="white"
        />
        <path
          d="M6.74431 16.7834H1.66137C1.40038 16.1955 1.25342 15.5443 1.25342 14.8602C1.25342 12.4606 3.03979 10.4791 5.35575 10.1725C5.31774 9.91661 5.29747 9.65815 5.29747 9.3921C5.29747 6.55417 7.59822 4.25342 10.4361 4.25342C13.0638 4.25342 15.2277 6.22477 15.5368 8.76877C15.9802 8.64461 16.449 8.57619 16.933 8.57619C19.0893 8.57619 20.934 9.90394 21.6966 11.7866C21.9323 11.7486 22.1705 11.7258 22.4163 11.7258C24.8082 11.7258 26.7466 13.6642 26.7466 16.0562C26.7466 16.3045 26.7238 16.5477 26.6858 16.7834H19.2464"
          stroke="#3C4858"
          stroke-width="2"
          stroke-miterlimit="10"
        />
        <path
          d="M19.5581 21.9627H23.9696C24 21.7777 24.0177 21.5851 24.0177 21.39C24.0177 19.5099 22.4948 17.987 20.6147 17.987C20.4221 17.987 20.2321 18.0048 20.0497 18.0352C19.4491 16.5554 17.9998 15.5114 16.3046 15.5114C15.9245 15.5114 15.5546 15.5647 15.2074 15.6635C14.9667 13.6643 13.264 12.1135 11.1989 12.1135C8.96907 12.1135 7.15989 13.9227 7.15989 16.1525C7.15989 16.3603 7.17509 16.5655 7.2055 16.7657C5.38618 17.0064 3.98242 18.5647 3.98242 20.4499C3.98242 20.9871 4.09898 21.499 4.30422 21.9601H19.5606L19.5581 21.9627Z"
          fill="white"
          stroke="#3C4858"
          stroke-width="2"
          stroke-miterlimit="10"
        />
      </svg>
    </Glyph>
  );
};

export default CloudGlyph;
