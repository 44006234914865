// import { useTranslation } from 'react-i18next';
// import Typography from '../../sharedComponents/Typography/Typography';
// import styles from './forecast.module.scss';
import { Group } from '@visx/group';
import { curveMonotoneX } from '@visx/curve';
import { AreaClosed, Bar, Line, LinePath } from '@visx/shape';
import { scaleLinear, scaleTime } from '@visx/scale';
import { AxisBottom, AxisLeft, AxisRight } from '@visx/axis';
import { GridColumns, GridRows } from '@visx/grid';
import { useParentSize } from '@visx/responsive';
import { localPoint } from '@visx/event';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { bisector } from '@visx/vendor/d3-array';
import { LinearGradient } from '@visx/gradient';
import { Annotation, Label, LineSubject } from '@visx/annotation';
import { ResizeObserver } from '@juggle/resize-observer';
import RainGlyph from './RainGlyph';
import { ForecastData, PolicyStatus } from '../../utils/types/Entity';
import CloudGlyph from './CloudGlyph';
import SunGlyph from './SunGlyph';
import SensibleGlyph from './SensibleGlyph';

const background = '#f3f3f3';
const temperatureColor = '#6c0036';
const rainColor1 = '#03ffe0';
const rainColor2 = '#557b90';

const defaultMargin = { top: 80, right: 30, bottom: 70, left: 40 };

type ForecastProps = {
  policyStatus: PolicyStatus;
  maxHeight?: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  data?: ForecastData;
  rainfallThreshold?: number;
  temperatureThreshold?: number;
};

type ThresholdDataPoint = [Date, number];
type ThresholdData = ThresholdDataPoint[];

const Forecast = ({
  policyStatus,
  margin = defaultMargin,
  data,
  rainfallThreshold,
  temperatureThreshold,
  maxHeight,
}: ForecastProps): JSX.Element => {
  const { width, parentRef } = useParentSize({ debounceTime: 150 });
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip<{
    time: Date;
    temperature: number;
    rainfall: number;
    temperatureY: number;
    rainfallY: number;
  }>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
    polyfill: ResizeObserver,
  });

  const height = maxHeight
    ? Math.min(width * 1.2, maxHeight - margin.top - margin.bottom)
    : width * 1.2;

  const DEFAULT_RAIN_THRESHOLD = 2;

  const temperatureData = data?.temperature_f_2m || data?.temperature_c_2m;
  if (!temperatureData || !data?.rainfall_m_surface) {
    return (
      <div ref={parentRef}>
        <svg width={width} height={height}>
          <rect
            x={0}
            y={0}
            width={width}
            height={height}
            fill={background}
            rx={14}
          />
        </svg>
      </div>
    );
  }

  // bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  // scales
  const dates = Object.keys(temperatureData).map((dateString) =>
    new Date(dateString).valueOf(),
  );
  const temperatures = Object.values(temperatureData);
  const rainfalls = Object.values(data.rainfall_m_surface).map(
    (rainfall) => rainfall * 1000,
  );

  const timeScale = scaleTime<number>({
    domain: [Math.min(...dates), Math.max(...dates)],
    range: [0, xMax],
  });

  const RAINFALL_SCALE_DOMAIN_DEFAULT_MIN = 0;
  const RAINFALL_SCALE_DOMAIN_DEFAULT_MAX = 10;

  const TEMPERATURE_SCALE_DOMAIN_DEFAULT_MIN = 20;
  const TEMPERATURE_SCALE_DOMAIN_DEFAULT_MAX = 90;

  const temperatureMinRoundToTen =
    Math.floor(
      Math.min(...temperatures, temperatureThreshold || Infinity) / 10,
    ) * 10;
  const temperatureMaxRoundToTen =
    Math.ceil(
      Math.max(...temperatures, temperatureThreshold || -Infinity) / 10,
    ) * 10;

  const temperatureScaleDomainMin = Math.min(
    temperatureMinRoundToTen,
    TEMPERATURE_SCALE_DOMAIN_DEFAULT_MIN,
  );
  const temperatureScaleDomainMax = Math.max(
    temperatureMaxRoundToTen,
    TEMPERATURE_SCALE_DOMAIN_DEFAULT_MAX,
  );

  const temperatureScale = scaleLinear<number>({
    domain: [temperatureScaleDomainMin, temperatureScaleDomainMax],
    nice: true,
    range: [yMax, 0],
  });
  const rainfallScale = scaleLinear<number>({
    domain: [
      RAINFALL_SCALE_DOMAIN_DEFAULT_MIN,
      Math.max(...rainfalls, RAINFALL_SCALE_DOMAIN_DEFAULT_MAX),
    ],
    nice: true,
    range: [yMax, 0],
  });

  const formatThresholdData = (
    forecastMetric?: Record<string, number>,
  ): ThresholdData => {
    if (!forecastMetric) {
      return [];
    }
    return Object.entries(forecastMetric).map(([dateString, value]) => [
      new Date(dateString),
      value,
    ]);
  };

  const thresholdTemperatureData: ThresholdData =
    formatThresholdData(temperatureData);
  const thresholdRainfallData: ThresholdData = formatThresholdData(
    data.rainfall_m_surface,
  ).map(([dateString, rainfall]) => [dateString, rainfall * 1000]);
  const thresholdCloudData: ThresholdData = formatThresholdData(
    data.cloudfraction_percent_all,
  );

  const bisectDate = bisector<ThresholdDataPoint, Date>(
    (data) => data[0],
  ).center;
  const handleMouseOver = (event: React.MouseEvent | React.TouchEvent) => {
    const coords = localPoint(event);
    if (!coords) return;

    const time = timeScale.invert(coords.x - margin.left);
    const index = bisectDate(thresholdTemperatureData, time);
    if (index >= thresholdTemperatureData.length) return;
    const temperatureDatum = thresholdTemperatureData[index];
    const rainfallDatum = thresholdRainfallData[index];

    showTooltip({
      tooltipLeft: coords.x - margin.left,
      tooltipTop: temperatureScale(temperatureDatum[1]),
      tooltipData: {
        time: temperatureDatum[0],
        temperature: temperatureDatum[1],
        rainfall: rainfallDatum[1],
        temperatureY: temperatureScale(temperatureDatum[1]),
        rainfallY: rainfallScale(rainfallDatum[1]),
      },
    });
  };

  const xTickDivisor = Math.ceil(33 / (xMax / temperatures.length));
  const xTickCount = Math.ceil(temperatures.length / xTickDivisor);

  const glyphDataPoints = !thresholdCloudData.length
    ? []
    : thresholdRainfallData
        .reduce<[Date, number, number][][]>((acc, cur, i) => {
          const dataPoint: [Date, number, number] = [
            ...cur,
            thresholdCloudData[i][1],
          ];
          if (i % xTickDivisor === 0) {
            acc.push([dataPoint]);
          } else {
            acc[acc.length - 1].push(dataPoint);
          }
          return acc;
        }, [])
        .map((dataPointGroup: [Date, number, number][]) => {
          const averageDate = new Date(
            dataPointGroup.reduce((acc, cur) => cur[0].getTime() + acc, 0) /
              dataPointGroup.length,
          );
          const maxRainfall = Math.max(
            ...dataPointGroup.map((dataPoint) => dataPoint[1]),
          );
          const maxCloud = Math.max(
            ...dataPointGroup.map((dataPoint) => dataPoint[2]),
          );
          return [averageDate, maxRainfall, maxCloud] as [Date, number, number];
        });

  const makeThresholdLine = ({
    perilScale,
    threshold,
    color,
    labelTitle,
    labelAlign,
  }: {
    perilScale: ReturnType<typeof scaleLinear<number>>;
    threshold: number;
    color: string;
    labelTitle: string;
    labelAlign: 'left' | 'right';
  }) => {
    return (
      <Annotation y={perilScale(threshold)}>
        <LineSubject
          orientation="horizontal"
          stroke={color}
          strokeWidth={2}
          strokeDasharray={20}
          min={0}
          max={width - margin?.left - margin.right}
        />
        <Label
          horizontalAnchor={labelAlign === 'right' ? 'end' : 'start'}
          title={labelTitle}
          showAnchorLine={false}
          showBackground={false}
          titleFontSize={10}
          titleFontWeight={400}
          maxWidth={200}
          x={
            labelAlign === 'right'
              ? timeScale(dates[dates.length - 1])
              : undefined
          }
          backgroundPadding={{
            top: 0,
            bottom: 2,
            left: labelAlign === 'left' ? 20 : 0,
            right: labelAlign === 'right' ? 20 : 0,
          }}
        />
      </Annotation>
    );
  };

  const TemperatureThresholdAnnotation =
    temperatureThreshold && policyStatus !== PolicyStatus.Upcoming ? (
      makeThresholdLine({
        perilScale: temperatureScale,
        threshold: temperatureThreshold,
        color: temperatureColor,
        labelTitle: 'Temperature Threshold',
        labelAlign: 'left',
      })
    ) : (
      <></>
    );

  const RainfallThresholdAnnotation =
    rainfallThreshold && policyStatus !== PolicyStatus.Upcoming ? (
      makeThresholdLine({
        perilScale: rainfallScale,
        threshold: rainfallThreshold,
        color: rainColor1,
        labelTitle: 'Rainfall Threshold',
        labelAlign: 'right',
      })
    ) : (
      <></>
    );

  const fahrenheitDataExists = !!data.temperature_f_2m;
  return (
    <div ref={parentRef}>
      <svg width={width} height={height} ref={containerRef}>
        <LinearGradient
          id="rain-gradient"
          fromOffset="50%"
          from={rainColor2}
          to={rainColor1}
          toOpacity={0.8}
        />
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill={background}
          rx={14}
        />
        <Group left={margin.left} top={margin.top}>
          <AxisRight
            scale={rainfallScale}
            label="rainfall (mm)"
            left={width - margin.right - margin.left}
          />
          <AxisBottom top={yMax} scale={timeScale} numTicks={xTickCount} />
          <AxisLeft scale={temperatureScale} />

          <GridRows
            scale={temperatureScale}
            width={xMax}
            height={yMax}
            stroke="#e0e0e0"
          />
          <GridColumns
            scale={timeScale}
            width={xMax}
            height={yMax}
            stroke="#e0e0e0"
          />
          <line x1={xMax} x2={xMax} y1={0} y2={yMax} stroke="#e0e0e0" />
          <AreaClosed<ThresholdDataPoint>
            data={thresholdRainfallData}
            curve={curveMonotoneX}
            x={(dataPoint) => timeScale(dataPoint[0]) ?? 0}
            y={(dataPoint) => rainfallScale(dataPoint[1]) ?? 0}
            yScale={rainfallScale}
            strokeWidth={1}
            stroke={'url(#rain-gradient)'}
            fill={'url(#rain-gradient)'}
          />
          <LinePath<ThresholdDataPoint>
            data={thresholdTemperatureData}
            curve={curveMonotoneX}
            x={(dataPoint) => timeScale(dataPoint[0]) ?? 0}
            y={(dataPoint) => temperatureScale(dataPoint[1]) ?? 0}
            stroke={temperatureColor}
            strokeWidth={1.5}
            strokeOpacity={0.8}
          />

          {TemperatureThresholdAnnotation}
          {RainfallThresholdAnnotation}

          {glyphDataPoints.map(
            (
              [date, rainfall, cloud]: [Date, number, number],
              index: number,
            ) => {
              const x = timeScale(date) - 16;
              const y = -50;

              const Glyph =
                rainfall >= (rainfallThreshold ?? DEFAULT_RAIN_THRESHOLD)
                  ? RainGlyph
                  : cloud >= 50
                    ? CloudGlyph
                    : SunGlyph;

              return (
                <Glyph
                  left={x}
                  top={y}
                  key={`glyph-${xTickDivisor}-${index}`}
                />
              );
            },
          )}
          {tooltipData && (
            <g>
              <Line
                from={{ x: tooltipLeft, y: 0 }}
                to={{ x: tooltipLeft, y: height - margin.bottom - margin.top }}
                stroke="#222"
                strokeWidth={2}
                pointerEvents="none"
                strokeDasharray="5,2"
              />
              <circle
                cx={tooltipLeft}
                cy={(tooltipTop || 0) + 1}
                r={4}
                fill="black"
                fillOpacity={0.1}
                stroke="black"
                strokeOpacity={0.1}
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                r={4}
                fill={temperatureColor}
                stroke={temperatureColor}
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft}
                cy={(tooltipData.rainfallY || 0) + 1}
                r={4}
                fill="black"
                fillOpacity={0.1}
                stroke="black"
                strokeOpacity={0.1}
                strokeWidth={2}
                pointerEvents="none"
              />
              <circle
                cx={tooltipLeft}
                cy={tooltipData.rainfallY}
                r={4}
                fill={rainColor1}
                stroke={rainColor1}
                strokeWidth={2}
                pointerEvents="none"
              />
            </g>
          )}
          <text
            x="-4"
            y={width - margin.right - margin.left - 5}
            transform="rotate(-90)"
            fontSize={12}
            textAnchor="end"
          >
            Rainfall (mm)
          </text>
          <text
            x="3"
            y="-5"
            transform="rotate(90)"
            fontSize={12}
            textAnchor="start"
          >
            {`Temperature ${fahrenheitDataExists ? `(°F)` : `(°C)`}`}
          </text>
        </Group>
        <Bar
          x={margin.left}
          y={margin.top}
          width={width - margin.left - margin.right}
          height={height - margin.top - margin.bottom}
          fill="transparent"
          rx={14}
          onTouchStart={handleMouseOver}
          onTouchMove={handleMouseOver}
          onMouseMove={handleMouseOver}
          onMouseLeave={() => hideTooltip()}
        />
        <SensibleGlyph
          left={width - margin.right - 100}
          top={height - margin.bottom + 30}
        />
      </svg>
      {tooltipOpen && (
        <TooltipInPortal
          // set this to random so it correctly updates with parent bounds
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
        >
          <br />
          <strong>{tooltipData?.time.toLocaleTimeString()}</strong>
          <br />
          <strong>Temperature:</strong> {tooltipData?.temperature.toFixed(1)}° F
          <br />
          <strong>Rainfall:</strong> {tooltipData?.rainfall.toFixed(2)} mm
        </TooltipInPortal>
      )}
    </div>
  );
};

export default Forecast;
