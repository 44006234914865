import { Glyph } from '@visx/glyph';

type GlyphProps = {
  left: number;
  top: number;
};
const SunGlyph = ({ left, top }: GlyphProps) => {
  return (
    <Glyph left={left} top={top}>
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none">
        <path
          d="M14 16.5435C15.4047 16.5435 16.5435 15.4047 16.5435 14C16.5435 12.5953 15.4047 11.4565 14 11.4565C12.5953 11.4565 11.4565 12.5953 11.4565 14C11.4565 15.4047 12.5953 16.5435 14 16.5435Z"
          fill="#FFE600"
          stroke="#F6B70F"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M14 1V8.63043"
          stroke="#F6B70F"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M14 19.1577V26.7881"
          stroke="#F6B70F"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M26.7881 14H19.1577"
          stroke="#F6B70F"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M8.63043 14H1"
          stroke="#F6B70F"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M23.0123 23.0123L17.6145 17.6145"
          stroke="#F6B70F"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M10.1735 10.1735L4.77563 4.77563"
          stroke="#F6B70F"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M4.77563 23.0123L10.1735 17.6145"
          stroke="#F6B70F"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M17.6145 10.1735L23.0123 4.77563"
          stroke="#F6B70F"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
      </svg>
    </Glyph>
  );
};

export default SunGlyph;
