import { useTranslation } from 'react-i18next';
import sensibleDate from '../../utils/types/SensibleDate';
import {
  type Claim,
  ClaimStatus,
  getPayoutStatus,
  getPolicyStatus,
  PayoutProvider,
  PayoutStatus,
  PolicyStatus,
} from '../../utils/types/Entity';
import Accordion from '../../sharedComponents/Accordion/Accordion';
import Typography from '../../sharedComponents/Typography/Typography';
import {
  PolicyListItemProps,
  PolicyListItemStatus,
} from './PolicyListItem.types';
import PolicyListItemSubtitle from '../PolicyListItemSubtitle/PolicyListItemSubtitle';
import styles from './policy-list-item.module.scss';
import Forecast from '../Forecast/Forecast';

const PolicyListItem: React.FC<PolicyListItemProps> = ({
  policy,
  clickHandler,
  isExpanded,
  currency,
  forecast,
  disableForecast,
}) => {
  const { t } = useTranslation();

  const ACCORDION_COLOR_VARIANT = 'snow';

  const { start, exposures } = policy;

  const policyClaims = exposures[0].claims;

  const lookaheadLimit = disableForecast ? 0 : 1000 * 60 * 60 * 24 * 14; // 2 weeks, if forecast enabled
  const disableDate = new Date(new Date().getTime() + lookaheadLimit);
  const disabledFuturePolicyDetails = sensibleDate.isBefore(
    disableDate,
    sensibleDate.parseRFC(start),
  );
  const isFuturePolicy = sensibleDate.isBefore(
    new Date(),
    sensibleDate.parseRFC(start),
  );
  const policyStatus = getPolicyStatus(policy);

  const totalPayoutAmount = policyClaims
    .map((c) => c.payout?.amount ?? 0)
    .reduce((acc, amount) => acc + amount, 0);

  const getPolicyListItemStatus = (claim: Claim): PolicyListItemStatus => {
    if (policyStatus === PolicyStatus.Upcoming) {
      return PolicyListItemStatus.Upcoming;
    }
    if (!claim) {
      return PolicyListItemStatus.NoPayout;
    }

    if (claim.payout) {
      const claimPayoutStatus = getPayoutStatus(claim.payout);

      if (claimPayoutStatus === PayoutStatus.Failed) {
        return PolicyListItemStatus.PayoutFailed;
      }
      if (claimPayoutStatus === PayoutStatus.Canceled) {
        return PolicyListItemStatus.PayoutCanceled;
      }
    }

    if (claim.status === ClaimStatus.Open) {
      return PolicyListItemStatus.Due;
    }
    if (claim.status === ClaimStatus.Processing) {
      return PolicyListItemStatus.Processing;
    }
    if (claim.status === ClaimStatus.Paid) {
      return PolicyListItemStatus.Paid;
    }
    if (claim.status === ClaimStatus.Expired) {
      return PolicyListItemStatus.NoPayout;
    }
    if (claim.status === ClaimStatus.Canceled) {
      return PolicyListItemStatus.NoPayout;
    }
    return PolicyListItemStatus.NoPayout;
  };

  // TODO: #2174 prepare enums for translations. Better practice
  const providerNameMap = {
    [PayoutProvider['ACH']]: t('label.ach'),
    [PayoutProvider['PayPal']]: t('label.paypal'),
  };

  const noPayoutStatusCheck = (claims: Claim[]): boolean => {
    return claims.every(
      (c) => getPolicyListItemStatus(c) === PolicyListItemStatus.NoPayout,
    );
  };

  const renderTitleElement = (
    subTitles: JSX.Element[] | JSX.Element,
  ): JSX.Element => {
    return (
      <div className={styles.titleElement}>
        <Typography variant="h5">
          {t('formatter.date', {
            val: sensibleDate.parseRFC(start),
            formatParams: {
              val: {
                month: 'long',
                day: 'numeric',
              },
            },
          })}
        </Typography>
        {!isFuturePolicy && <div>{subTitles}</div>}
      </div>
    );
  };

  const claimsWithPayouts: Claim[] | [] = policyClaims.filter(
    (claim) => claim.payout,
  );
  const renderPayoutContent = (): JSX.Element => {
    const statusArray = claimsWithPayouts.map((claim) => {
      if (claim.payout) {
        return getPayoutStatus(claim.payout);
      }
    });

    if (
      statusArray.includes(PayoutStatus.Pending) ||
      statusArray.includes(PayoutStatus.Completed)
    ) {
      return (
        <div className={styles.payoutContent}>
          <Typography variant="label-3">
            {t('formatter.currency', { val: totalPayoutAmount, currency })}{' '}
            {claimsWithPayouts[0]?.payout?.provider
              ? t('sunshine.label.reimbursed_via', {
                  provider:
                    providerNameMap[claimsWithPayouts[0]?.payout?.provider],
                })
              : t('sunshine.label.reimbursed')}
          </Typography>
        </div>
      );
    }

    return <></>;
  };

  const peril = policy.exposures[0].perils[0];
  const rainThreshold =
    peril.name === 'rainfall' ? peril.parameterThresholdUpper : undefined;
  const temperatureThreshold =
    peril.name === 'temperature' ? peril.parameterThresholdUpper : undefined;
  const contentElement = (
    <div className={styles.contentElement}>
      {!disableForecast && (
        <Forecast
          policyStatus={policyStatus}
          data={forecast}
          maxHeight={460}
          rainfallThreshold={rainThreshold}
          temperatureThreshold={temperatureThreshold}
        />
      )}
      {policyStatus === PolicyStatus.Upcoming && (
        <Typography
          variant="label-3"
          format="punctuated sentence"
          className={styles.upcomingMessage}
        >
          Your reimbursement details will appear here once the trip starts
        </Typography>
      )}
      {!!claimsWithPayouts.length && renderPayoutContent()}
    </div>
  );

  if (noPayoutStatusCheck(policyClaims)) {
    const titleElement = renderTitleElement(
      <PolicyListItemSubtitle
        itemStatus={PolicyListItemStatus.NoPayout}
        claim={policyClaims[0]}
        currency={currency}
      />,
    );
    return (
      <div className={styles.container}>
        <Accordion
          title={titleElement}
          content={contentElement}
          isExpanded={isExpanded}
          disabled={disabledFuturePolicyDetails}
          colorVariant={ACCORDION_COLOR_VARIANT}
          clickHandler={() => clickHandler(policy.id)}
        />
      </div>
    );
  }

  const bundlePolicyClaimsByStatus = (
    claims: Claim[],
  ): Record<PolicyListItemStatus, Claim[]> => {
    const bundledClaims: Record<PolicyListItemStatus, Claim[]> = claims.reduce<
      Record<PolicyListItemStatus, Claim[]>
    >(
      (result, claim) => {
        const status = getPolicyListItemStatus(claim);
        result[status].push(claim);
        return result;
      },
      {
        [PolicyListItemStatus.Due]: [],
        [PolicyListItemStatus.Processing]: [],
        [PolicyListItemStatus.Paid]: [],
        [PolicyListItemStatus.NoPayout]: [],
        [PolicyListItemStatus.Upcoming]: [],
        [PolicyListItemStatus.PayoutFailed]: [],
        [PolicyListItemStatus.ClaimCanceled]: [],
        [PolicyListItemStatus.PayoutCanceled]: [],
      },
    );

    return bundledClaims;
  };

  const groupClaimsBy = (
    claims: Claim[],
    getGroupByKey: (claim: Claim) => string,
  ): Record<string, Claim[]> => {
    return claims.reduce<Record<string, Claim[]>>((result, claim) => {
      const datePayoutPaid = getGroupByKey(claim);
      if (result[datePayoutPaid]) {
        result[datePayoutPaid].push(claim);
      } else {
        result[datePayoutPaid] = [claim];
      }
      return result;
    }, {});
  };

  const renderLineItemClaims = (
    claims: Claim[],
    getGroupByKey: (claim: Claim) => string,
  ): JSX.Element[] => {
    const payoutByDate = groupClaimsBy(claims, getGroupByKey);

    const valueArray = Object.values(payoutByDate);
    return valueArray.reduce<JSX.Element[]>((result, value) => {
      if (value.length > 1) {
        const totalPaidAmount = value.reduce(
          (acc, amount) => acc + (amount.payout?.amount ?? 0),
          0,
        );
        result.push(
          <PolicyListItemSubtitle
            key={value[0].id}
            itemStatus={getPolicyListItemStatus(value[0])}
            claim={value[0]}
            totalPayoutAmount={totalPaidAmount}
            currency={currency}
          />,
        );
      } else {
        result.push(
          <PolicyListItemSubtitle
            key={value[0].id}
            itemStatus={getPolicyListItemStatus(value[0])}
            claim={value[0]}
            currency={currency}
          />,
        );
      }
      return result;
    }, []);
  };

  const renderAllPolicyClaims = (claims: Claim[]): JSX.Element[] => {
    const sanitizedNoPayout = claims.filter(
      (claim) =>
        getPolicyListItemStatus(claim) !== PolicyListItemStatus.NoPayout,
    );

    const claimsBundledByStatus = bundlePolicyClaimsByStatus(sanitizedNoPayout);

    const claimsList = Object.values(claimsBundledByStatus)
      .filter((subArray) => subArray.length > 0)
      .map((c) => {
        if (getPolicyListItemStatus(c[0]) === PolicyListItemStatus.Paid) {
          return renderLineItemClaims(
            c,
            (claim) => claim.payout?.completedAt?.split('T')[0] ?? '',
          );
        } else if (
          getPolicyListItemStatus(c[0]) === PolicyListItemStatus.PayoutFailed
        ) {
          return renderLineItemClaims(
            c,
            (claim) => claim.payout?.failedAt?.split('T')[0] ?? '',
          );
        } else {
          const totalAmount = c.reduce(
            (acc, value) => acc + (value.claimAmount ?? 0),
            0,
          );
          return [
            <PolicyListItemSubtitle
              key={c[0].id}
              itemStatus={getPolicyListItemStatus(c[0])}
              claim={c[0]}
              totalPayoutAmount={totalAmount}
              currency={currency}
            />,
          ];
        }
      })
      .flat();
    return claimsList;
  };

  const titleElement = renderTitleElement(renderAllPolicyClaims(policyClaims));

  return (
    <div className={styles.container}>
      <Accordion
        title={titleElement}
        content={contentElement}
        isExpanded={isExpanded}
        disabled={disabledFuturePolicyDetails}
        colorVariant={ACCORDION_COLOR_VARIANT}
        clickHandler={() => clickHandler(policy.id)}
      />
    </div>
  );
};

export default PolicyListItem;
